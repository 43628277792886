<template>
    <div class="orders-page">
        <Orders />
    </div>
</template>

<script>
import Orders from "@/views/orders/components/Orders.vue";
export default {
    name: "OrdersPage",
    components: { Orders }
}
</script>