import { defineStore } from 'pinia'


export const useOrderStore = defineStore('orderStore', {

    state: () => {
        return {
            refresherInterval: null,
            products: []
        }
    },
    actions: {
        stopRefresherInterval() {
            if (this.refresherInterval) {
                clearInterval(this.refresherInterval)
            }
        },
        setRefresherInterval(interval, duration) {
            this.stopRefresherInterval()
            this.refresherInterval = setInterval(interval, duration)
        }
    }
})